import React, { useEffect, useState } from 'react'
import { InputContainer, InputFormContainer, InputOutletContainer, InputSection, MobileTableContainer, WideTableContainer } from '../styles/Container.styled'
import { Input, InputFormHeading, InputMainHeadingContainer } from '../styles/Input.styled'
import { MDBDataTable } from 'mdbreact';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../layout/Loader';
import { clearErrors, deleteHolding, getAdminHoldings, removeHolding, reset } from '../../features/footprint/holdingSlice';
import { AddMainRecordButton, StyledLink } from '../styles/Button.styled';
import { InputForm } from '../styles/Form.styled';
import { IHolding } from '../../features/footprint/holdingSlice.interfaces';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

type Props = {}

type IHoldingListWideData = {
    columns: {
        label: string,
        field: string,
        sort: string
    }[],
    rows: {
        reference: React.ReactNode,
        region: string,
        systemDescription: string,
        email: React.ReactNode,
        actions: React.ReactNode
    }[]
}

type IHoldingListMobileData = {
    columns: {
        label: string,
        field: string,
        sort: string
    }[],
    rows: {
        reference: React.ReactNode,
        email: React.ReactNode,
        actions: React.ReactNode
    }[]
}

const AdminHoldingsList = (props: Props) => {

    const [selectedHoldingForDelete, setSelectedHoldingForDelete] = useState("");
    const [selectedMembershipNumber, setSelectedMembershipNumber] = useState(0);
    const [selectedHoldingEmail, setSelectedHoldingEmail] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pulledData, setPulledData] = useState(false);

    const { id } = useParams();

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { loading, errors, holdings, isDeleted, paginationMetaData } = useAppSelector(state => state.holding)
    const { user } = useAppSelector(state => state.auth)
    const { users } = useAppSelector(state => state.user)


    useEffect(() => {
        if (!pulledData) {
            dispatch(getAdminHoldings({ PageNumber: currentPage }));
            setPulledData(true);
        }

        if (isDeleted) {
            toast.dismiss();
            toast.success("Holding deleted successfully");
            dispatch(reset());
            dispatch(getAdminHoldings({ PageNumber: currentPage }));
        }

        if (paginationMetaData) {
            setCurrentPage(paginationMetaData.currentPage)
        }

        if (errors) {
            toast.error(String(errors))
            dispatch(clearErrors)
        }

    }, [dispatch, errors, id, isDeleted, pulledData, paginationMetaData])

    const handlerNextPage = () => {
        if (paginationMetaData && (paginationMetaData?.currentPage < paginationMetaData?.totalPages)) {
            toast.dismiss();
            toast.info("Retrieving information...");
            dispatch(getAdminHoldings({ PageNumber: currentPage + 1 }));
        }
    }

    const handlerPrevPage = () => {
        if (paginationMetaData && (paginationMetaData?.currentPage > 1)) {
            toast.dismiss();
            toast.info("Retrieving information...");
            dispatch(getAdminHoldings({ PageNumber: currentPage - 1 }));
        }
    }

    const Actions = (props: { element: IHolding }) => {
        return (
            <>
                <div className="dropdown">
                    <button className="btn btn-secondary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-solid fa-ellipsis"></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <StyledLink to={`/holdings/footprint/new/${props.element.id}`} className="ml-2 py-1 px-1">
                                <i className="fa-solid fa-plus mx-2 my-2" style={{ color: "#000" }}></i>Create Footprint
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink to={`/holding/${props.element.id}`} className="py-1 px-1 ml-2">
                                <i className="fa-solid fa-pen-to-square mx-2 my-2" style={{ color: "#000" }}></i>Edit Holding
                            </StyledLink>
                        </li>
                        <li>
                            <StyledLink to={`/holdings/footprints/${props.element.id}`} className="py-1 px-1 ml-2">
                                <i className="fa-solid fa-list mx-2 my-2" style={{ color: "#000" }}></i>Footprints
                            </StyledLink>
                        </li>
                        {!props.element.isArchived && (
                            <li>
                                <StyledLink to={"#"} onClick={() => updateSelectedHoldingForDelete(props.element.id)} className="py-1 px-1 ml-2 border-none cursor-pointer" data-bs-toggle="modal" data-bs-target="#exampleModalCenter1">
                                    <i className="fa-solid fa-box-archive mx-2 my-2" style={{ color: "crimson" }}></i> Archive Holdings
                                </StyledLink>
                            </li>
                        )}
                        <li>
                            <StyledLink to={"#"} onClick={() => updateSelectedHoldingForDelete(props.element.id)} className="py-1 px-1 ml-2 border-none cursor-pointer" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                                <i className="fa-solid fa-trash mx-2 my-2" style={{ color: "crimson" }}></i> Remove From System
                            </StyledLink>
                        </li>
                    </ul>
                </div>
            </>
        )
    }

    const Email = (props: { element: IHolding }) => {

        return (
            <>
                <Tippy content={<span>Membership Number: {props.element.membershipNumber ?? "None"}</span>}>
                    <div>
                        {props.element.email}
                    </div>
                </Tippy>

            </>
        )
    }

    const Reference = (props: { element: IHolding }) => {

        return (
            <>
                <div className='d-flex'>
                    {props.element.reference} {props.element.isArchived && (<Tippy content="Archived">
                        <div className='mx-2'>
                            <i className="fa-solid fa-box-archive" style={{ color: 'crimson' }}></i>
                        </div>
                    </Tippy>)}
                </div>



            </>
        )
    }

    const setWideHoldings = () => {
        const data: IHoldingListWideData = {
            columns: [
                {
                    label: 'Reference',
                    field: 'reference',
                    sort: 'asc'
                },
                {
                    label: 'Region',
                    field: 'region',
                    sort: 'asc'
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc'
                },
                {
                    label: 'Stud Farm Description',
                    field: 'systemDescription',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'asc'
                }

            ],
            rows: []
        }

        holdings && holdings.forEach(element => {
            data.rows.push({
                reference: <Reference element={element} />,
                region: element.region,
                email: <Email element={element} />,
                systemDescription: element.systemDescription,
                actions: <Actions element={element} />
            })
        });

        return data;
    }

    const setMobileHoldings = () => {
        const data: IHoldingListMobileData = {
            columns: [
                {
                    label: 'Reference',
                    field: 'reference',
                    sort: 'asc'
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'asc'
                }

            ],
            rows: []
        }

        holdings && holdings.forEach(element => {
            data.rows.push({
                reference: element.reference,
                email: element.email ?? "",
                actions: <Actions element={element} />
            })
        });

        return data;
    }

    const updateSelectedHoldingForDelete = (id: string) => {
        setSelectedHoldingForDelete(id);
    }

    const deleteSelectedHolding = () => {
        if (selectedHoldingForDelete !== "") {
            toast.info("Archiving holding...")
            dispatch(deleteHolding({ id: selectedHoldingForDelete }))
        }
    }

    const removeSelectedHolding = () => {
        if (selectedHoldingForDelete !== "") {
            toast.info("Deleting holding...")
            dispatch(removeHolding({ id: selectedHoldingForDelete }))
        }
    }

    const updateSelectedMembership = () => {

    }

    const changeMembershipHolding = () => {

    }


    return (
        <>
            <InputContainer>
                <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>
                <InputFormContainer>
                    <InputFormHeading>Admin Holdings List</InputFormHeading>

                    <InputForm>
                        {/* <AddMainRecordButton src='/icons/plus.png' alt='Add main record' onClick={() => navigate("/holding/new")} /> */}

                        <div className='my-3'>
                            {loading ? <Loader /> : (
                                <>
                                    <WideTableContainer>
                                        <MDBDataTable
                                            data={setWideHoldings()}
                                            borderless
                                            hover
                                            noBottomColumns
                                            striped
                                            displayEntries={false}
                                            paging={false}
                                        />
                                    </WideTableContainer>
                                    <MobileTableContainer>
                                        <MDBDataTable
                                            data={setMobileHoldings()}
                                            borderless
                                            hover
                                            noBottomColumns
                                            striped
                                            displayEntries={false}
                                            paging={false}
                                        />
                                    </MobileTableContainer>
                                </>
                            )}
                        </div>
                    </InputForm>

                    {paginationMetaData && (
                        <>
                            <button className='nav-btn back-btn' disabled={paginationMetaData.currentPage === 1} onClick={handlerPrevPage}>Back</button>
                            <div className='pagination-info'>
                                <div>
                                    <div>{paginationMetaData.currentPage} / {paginationMetaData.totalPages}</div>
                                    <div>Max page size: {paginationMetaData.pageSize} items</div>
                                </div>
                            </div>
                            <button className='nav-btn next-btn' disabled={paginationMetaData.currentPage === paginationMetaData.totalPages} onClick={handlerNextPage}>Next</button>
                        </>
                    )}

                </InputFormContainer>
            </InputContainer>
            <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Are you sure?</h5>
                            <button type="button" className="btn btn-secondary close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>This action <strong>CANNOT</strong> be undone. This would permanently delete the holding.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Back</button>
                            <button type="button" className="btn btn-danger" onClick={deleteSelectedHolding} data-bs-dismiss="modal">Yes, Remove</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModalCenter1" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle1">Are you sure?</h5>
                            <button type="button" className="btn btn-secondary close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>This action <strong>CANNOT</strong> be undone unless you contact the site administrator.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Back</button>
                            <button type="button" className="btn btn-danger" onClick={removeSelectedHolding} data-bs-dismiss="modal">Yes, Archive</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModalCenter2" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle2" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle2">Change TBA Membership Number</h5>
                            <button type="button" className="btn btn-secondary close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>This action <strong>CANNOT</strong> be undone unless you contact the site administrator.</p>
                            <div>
                                {/* <Input
                                    name="reference"
                                    onChange={handleChange}
                                    value={values.reference}
                                    className='form-control'
                                    data-role='none'
                                /> */}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Back</button>
                            <button type="button" className="btn btn-danger" onClick={removeSelectedHolding} data-bs-dismiss="modal">Yes, Archive</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminHoldingsList